// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#about, #skills, #contact {
    scroll-margin-top: 65px;
}

.background-alternating-1 {
    /*background-image: linear-gradient(150deg, rgb(15, 33, 103) 0%, rgba(4, 20, 68, 1) 90%);*/
    /*background-color: black;*/
    background-color: rgba(0, 0, 0, 0.7);
    color: lightgray;
    /*color: white;*/

    width: 60%;
    margin: 0% 35% 15% 5%;
    box-shadow: 0 0 5px lightgray;
}

.background-alternating-2 {
    /*background-image: linear-gradient(150deg, rgb(25, 3, 106) 0%, rgba(13, 3, 53, 1) 90%);*/
    /*background-color: #272626;*/
    background-color: rgba(0, 0, 0, 0.7);
    /*background-color: black;*/
    color: lightgray;
    /*color: white;*/

    width: 60%;
    margin: 10% 5% 15% 35%;
    box-shadow: 0 0 5px lightgray;
}

.divider {
    background-color: lightgray;
    height: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/Homepage.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0FAA0F;IAC1F,2BAA2B;IAC3B,oCAAoC;IACpC,gBAAgB;IAChB,gBAAgB;;IAEhB,UAAU;IACV,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,yFAAyF;IACzF,6BAA6B;IAC7B,oCAAoC;IACpC,2BAA2B;IAC3B,gBAAgB;IAChB,gBAAgB;;IAEhB,UAAU;IACV,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;IAC3B,WAAW;AACf","sourcesContent":["#about, #skills, #contact {\n    scroll-margin-top: 65px;\n}\n\n.background-alternating-1 {\n    /*background-image: linear-gradient(150deg, rgb(15, 33, 103) 0%, rgba(4, 20, 68, 1) 90%);*/\n    /*background-color: black;*/\n    background-color: rgba(0, 0, 0, 0.7);\n    color: lightgray;\n    /*color: white;*/\n\n    width: 60%;\n    margin: 0% 35% 15% 5%;\n    box-shadow: 0 0 5px lightgray;\n}\n\n.background-alternating-2 {\n    /*background-image: linear-gradient(150deg, rgb(25, 3, 106) 0%, rgba(13, 3, 53, 1) 90%);*/\n    /*background-color: #272626;*/\n    background-color: rgba(0, 0, 0, 0.7);\n    /*background-color: black;*/\n    color: lightgray;\n    /*color: white;*/\n\n    width: 60%;\n    margin: 10% 5% 15% 35%;\n    box-shadow: 0 0 5px lightgray;\n}\n\n.divider {\n    background-color: lightgray;\n    height: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
