import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";


export default function Companies({formRef}) {
    const tabRefs = [useRef(), useRef(), useRef()];
    const tabContentRefs = [useRef(), useRef(), useRef()];
    const [currentTab, setCurrentTab] = useState(0);
    const {t, i18n} = useTranslation("common");

    useEffect(() => {
        for (let i = 0; i < tabRefs.length; i++) {
            const tab = tabRefs[i].current;
            const tabContent = tabContentRefs[i].current;
            if (i === currentTab) {
                tab.className = "w3-bar-item w3-button w3-dark-gray w3-text-light-gray";
                tabContent.className = "w3-show";
            } else {
                tab.className = "w3-bar-item w3-button";
                tabContent.className = "w3-hide";
            }
        }

        formRef.current = tabContentRefs[currentTab].current;
    }, [currentTab, formRef, tabContentRefs, tabRefs])

    return (<>
        <div className="w3-padding">
            <h2>{t("companies.header")}</h2>
            <div className="w3-bar w3-round w3-light-gray w3-margin-bottom">
                <button ref={tabRefs[0]} onClick={() => setCurrentTab(0)}>{t("companies.description")}</button>
                <button ref={tabRefs[1]} onClick={() => setCurrentTab(1)}>{t("companies.url")}</button>
                <button ref={tabRefs[2]} onClick={() => setCurrentTab(2)}>{t("companies.tags")}</button>
            </div>

            <form ref={tabContentRefs[0]} id="description">
                <textarea name="user_input" className="w3-input w3-light-gray w3-round" style={{resize: "vertical"}}
                          defaultValue="A company specializing in clothing, using sustainable material sources and innovative technologies"/>
            </form>

            <form ref={tabContentRefs[1]} id="url" className="w3-hide">
                <textarea name="user_input" className="w3-input w3-light-gray w3-round" style={{resize: "vertical"}}
                          defaultValue="https://www.accord-consulting.com/sage/sage-intacct.htm"/>
            </form>

            <form ref={tabContentRefs[2]} id="tags" className="w3-hide">
                <textarea name="user_input" className="w3-input w3-light-gray w3-round" style={{resize: "vertical"}}
                          defaultValue="clothing, sustainable, techwear"/>
            </form>
        </div>
    </>);
}
