import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Spinner from "../spinner/Spinner";

export default function Summarize() {
    const [response, setResponse] = useState("");
    const [output, setOutput] = useState(<div/>);
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const {t, i18n} = useTranslation("common");

    useEffect(() => {
        if (loading) {
            setOutput(<Spinner/>);
        } else if (response !== "") {
            setOutput(
                <textarea className="w3-input w3-light-gray w3-round w3-padding" style={{resize: "vertical"}}
                          defaultValue={response}/>
            );
        }
    }, [response, loading]);

    async function submitSamplePDF(event) {
        const formData = new FormData();
        const blob = await fetch("/sample_files/sample.pdf").then(r => r.blob());
        formData.append("file", blob);
        submitFormData(formData);
    }

    function handleSubmit(event) {
        event.preventDefault();
        submitFormData(new FormData(formRef.current));
    }

    function submitFormData(formData) {
        formData.append("language", i18n.resolvedLanguage);

        setLoading(true);
        fetch("/summarize", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()
        ).then(data => {
            setLoading(false);
            setResponse(data.output);
        });
    }

    return (
        <>
            <div style={{padding: "60px"}}>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <input
                        id="file" name="file" type="file"
                        accept=".docx, .pdf" className="w3-input"
                    />
                    <input type="submit" value={t.submit}/>
                </form>

                <div className="w3-container w3-round-medium w3-border w3-margin-top">
                    <iframe
                        title="Sample PDF file"
                        src="/sample_files/sample_document_image.jpg" alt="Document"
                        className="w3-round" style={{height: "400px", width: "auto", marginTop: "30px"}}
                    />
                    <br/>
                    <button
                        className="w3-button w3-round w3-blue" onClick={submitSamplePDF} style={{margin: "15px"}}
                    >{t("sample.document")}</button>
                </div>

                {output}
            </div>
        </>
    );
}
