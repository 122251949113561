import './App.css';
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import Layout from "./components/Layout";
import TextPrompt from "./components/demos/TextPrompt";
import Homepage from "./components/homepage/Homepage";
import Summarize from "./components/demos/Summarize";
import ImageQA from "./components/demos/ImageQA";
import Scraper from "./components/demos/Scraper";
import ImageCaption from "./components/demos/ImageCaption";
import TableQuestion from "./components/demos/TableQuestion";
import Transcribe from "./components/demos/Transcribe";
import Youtube from "./components/demos/Youtube";
import Marketing from "./components/marketing/Marketing";
import {useEffect} from "react";

function App() {
    const reload = () => window.location.reload();
    const location = useLocation();

    useEffect(() => {
        const staticHtmlPages = [
            "/homepage.html"
        ]

        if (staticHtmlPages.includes(location.pathname)) {
            reload();
        }
    }, [location]);

    return (
        <Routes>
            {/*<Route path="/" element={<Navigate to="/homepage.html" replace={true} />}/>*/}
            <Route element={<Layout/>}>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/prompt" element={<TextPrompt/>}/>
                <Route path="/summarize" element={<Summarize/>}/>
                <Route path="/image-qa" element={<ImageQA/>}/>
                <Route path="/scraper" element={<Scraper/>}/>
                <Route path="/caption" element={<ImageCaption/>}/>
                <Route path="/table-question" element={<TableQuestion/>}/>
                <Route path="/transcribe" element={<Transcribe/>}/>
                <Route path="/youtube" element={<Youtube/>}/>
                <Route path="/marketing" element={<Marketing/>}/>
            </Route>
            {/* Paths to static HTML */}
            <Route path="/homepage.html"/>
        </Routes>
    );
}

export default App;
