// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#outlet {
    /*margin-top: 60px;*/
    flex: 1 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,cAAc;AAClB","sourcesContent":["#wrapper {\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n#outlet {\n    /*margin-top: 60px;*/\n    flex: 1 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
