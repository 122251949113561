export default function Skills() {
    return (
        <>
            <div id="skills" className="w3-container background-alternating-2">

                <div className="w3-large w3-padding-top-48 w3-padding-large">
                    {/*<div className="w3-padding w3-large">
                        <h1 className="w3 large">
                            <i className="fa fa-exchange" aria-hidden="true"></i> Usługi Delegate AI
                        </h1>
                        <p>
                            Nasze usługi to nie nie tylko AI! Oferujemy działania w znacznie szerszym zakresie ogólnie
                            pojętego IT.
                        </p>
                        <h3>Proponujemy Państwu współpracę w obszarach takich jak:</h3>
                    </div>*/}

                    <div className="w3-col m6 w3-large">
                        <h1 className="w3 large">
                            <i className="fa fa-exchange" aria-hidden="true"></i> Usługi Delegate AI
                        </h1>
                        <p>
                            Nasze usługi to nie nie tylko AI! Oferujemy działania w znacznie szerszym zakresie ogólnie
                            pojętego IT.
                        </p>
                        <h3>Proponujemy Państwu współpracę w obszarach takich jak:</h3>
                    </div>
                    <div className="w3-col m6">
                        <img className="w3-image w3-round-large w3-grayscale" src="/images/tecza2.jpg" alt="Buildings"
                             width="700" height="394"/>
                    </div>
                </div>

                <div className="w3-container"/>

                <div className="w3-padding w3-padding-top-32">
                    <h2>
                        <i className="fa fa-calendar-check-o" aria-hidden="true"/>&nbsp;
                        Projektowanie i implementacja
                    </h2>
                    {/*<div className="w3-padding w3-half">*/}
                    {/*    <h3>*/}
                    {/*        <i className="fa fa-mobile fa-2x" aria-hidden="true"></i>*/}
                    {/*        &nbsp;&nbsp;&nbsp; Aplikacje mobilne*/}
                    {/*    </h3>*/}
                    {/*    /!*<div className="w3-large">*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Projektujemy i tworzymy oprogramowania do działania urządzeń przenośnych.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Projektujemy i tworzymy oprogramowania do działania urządzeń*!/*!/*/}
                    {/*    /!*    /!*    przenośnych, takich jak na przykład smartfon.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Mogą być to aplikacje usprawniające i rozszerzające funkcje urządzenia, a także*!/*!/*/}
                    {/*    /!*    /!*    dostarczające użytkownikom rozrywki czy narzędzi do rozwijania swoich pasji, czy*!/*!/*/}
                    {/*    /!*    /!*    zdobywania*!/*!/*/}
                    {/*    /!*    /!*    wiedzy.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}

                    {/*<div className="w3-padding w3-half">*/}
                    {/*    <h3>*/}
                    {/*        <i className="fa fa-server" aria-hidden="true"></i>*/}
                    {/*        &nbsp;&nbsp;&nbsp; Aplikacje serwerowe*/}
                    {/*    </h3>*/}
                    {/*    /!*<div className="w3-large">*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Specjalizujemy się w tworzeniu zaawansowanych aplikacji serwerowych zapewniając stabilne*!/*!/*/}
                    {/*    /!*    /!*    i efektywne środowisko do zarządzania danymi, aplikacjami oraz usługami sieciowymi.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Optymalizujemy procesy biznesowe, zwiększamy bezpieczeństwo danych oraz poprawiamy*!/*!/*/}
                    {/*    /!*    /!*    dostępność i wydajność systemów informatycznych.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}

                    {/*<div className="w3-padding w3-half">*/}
                    {/*    <h3>*/}
                    {/*        <i className="fa fa-desktop" aria-hidden="true"></i>*/}
                    {/*        &nbsp;&nbsp;&nbsp; Aplikacje desktopowe*/}
                    {/*    </h3>*/}
                    {/*    /!*<div className="w3-large">*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Zajmujemy się oprogramowaniem instalowanym na urządzeniach takich jak komputer*!/*!/*/}
                    {/*    /!*    /!*    stacjonarny, laptop, tablet, smartfon.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Zajmujemy się oprogramowaniem instalowanym na urządzeniach takich jak komputer*!/*!/*/}
                    {/*    /!*    /!*    stacjonarny, laptop, tablet, smartfon, w przeciwieństwie do aplikacji mobilnych.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Narzędzia te są funkcjonalne bez konieczności połączenia używanego przez nas urządzenia*!/*!/*/}
                    {/*    /!*    /!*    do internetu.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}

                    {/*<div className="w3-padding w3-half">*/}
                    {/*    <h3>*/}
                    {/*        <i className="fa fa-graduation-cap" aria-hidden="true"></i>*/}
                    {/*        &nbsp;Pilotażowe wdrożenia AI*/}
                    {/*    </h3>*/}
                    {/*    /!*<div className="w3-large">*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Oferujemy pilotażowe wdrożenia sztucznej inteligencji, pozwalające na testowanie*!/*!/*/}
                    {/*    /!*    /!*    rozwiązań AI w kontrolowanych warunkach.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*    /!*<p>*!/*!/*/}
                    {/*    /!*    /!*    Oferujemy pilotażowe wdrożenia sztucznej inteligencji, które umożliwiają*!/*!/*/}
                    {/*    /!*    /!*    Państwa organizacji testowanie i ocenę innowacyjnych rozwiązań AI w kontrolowanych*!/*!/*/}
                    {/*    /!*    /!*    warunkach, zanim zdecydują się Państwo na ich implementację na większą skalę.*!/*!/*/}
                    {/*    /!*    /!*</p>*!/*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}
                </div>

                <h2 className="w3-padding">
                    <i className="fa fa-database" aria-hidden="true"/>
                    &nbsp;Analiza danych
                </h2>
                {/*<div className="w3-padding w3-large">*/}
                {/*    <p>*/}
                {/*        Korzystając z najnowszych technologii w dziedzinie analizy danych, pomagamy naszym klientom*/}
                {/*        odkrywać ukryte wzorce, trendy oraz korelacje, które mogą wpłynąć na strategiczne decyzje*/}
                {/*        biznesowe.*/}
                {/*    </p>*/}
                {/*    /!*<p>*!/*/}
                {/*    /!*    Nasz zespół ekspertów wykorzystuje zaawansowane narzędzia takie jak sztuczna inteligencja,*!/*/}
                {/*    /!*    uczenie maszynowe i big data, aby przekształcać surowe dane w przystępne i wartościowe*!/*/}
                {/*    /!*    informacje dla Państwa firmy.*!/*/}
                {/*    /!*</p>*!/*/}
                {/*    /!*<p>*!/*/}
                {/*    /!*    Dzięki indywidualnemu podejściu do każdego projektu, jesteśmy w stanie zaproponować rozwiązania*!/*/}
                {/*    /!*    dostosowane do specyfiki działalności Państwa firmy, co pozwala na optymalizację procesów*!/*/}
                {/*    /!*    decyzyjnych i zwiększenie konkurencyjności na rynku.*!/*/}
                {/*    /!*</p>*!/*/}
                {/*</div>*/}

                {/*<div className="w3-container w3-border-bottom"/> */}

                <h2 className="w3-padding">
                    <i className="fa fa-handshake-o" aria-hidden="true"/>&nbsp;
                    Instalacja, wdrożenie i administrowanie infrastruktury IT bazującej na oprogramowaniu open source
                </h2>
                {/*<div className="w3-padding w3-large">*/}
                {/*    <p>*/}
                {/*        Nasza firma specjalizuje się w instalacji, wdrożeniu i administrowaniu infrastruktury IT, która*/}
                {/*        bazuje na oprogramowaniu open source.*/}
                {/*    </p>*/}
                {/*    /!*<p>*!/*/}
                {/*    /!*    Dzięki naszemu doświadczeniu jesteśmy w stanie zapewnić wysoką skalowalność, bezpieczeństwo i*!/*/}
                {/*    /!*    elastyczność systemów IT, korzystając z zalet wolnego oprogramowania, takich jak Linux, Apache*!/*/}
                {/*    /!*    czy MySQL.*!/*/}
                {/*    /!*</p>*!/*/}
                {/*    /!*<p>*!/*/}
                {/*    /!*    Świadczymy kompleksowe usługi doradztwa, konfiguracji oraz bieżącego wsparcia, aby nasi klienci*!/*/}
                {/*    /!*    mogli skupić się na rozwoju swoich biznesów, mając pewność, że ich infrastruktura IT jest*!/*/}
                {/*    /!*    niezawodna i efektywnie zarządzana.*!/*/}
                {/*    /!*</p>*!/*/}
                {/*</div>*/}
            </div>
        </>
    );
}