import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";


export default function Product({formRef}) {
    const tabRefs = [useRef(), useRef(), useRef()];
    const tabContentRefs = [useRef(), useRef(), useRef()];
    const [currentTab, setCurrentTab] = useState(0);
    const {t, i18n} = useTranslation("common");


    useEffect(() => {
        for (let i = 0; i < tabRefs.length; i++) {
            const tab = tabRefs[i].current;
            const tabContent = tabContentRefs[i].current;
            if (i === currentTab) {
                tab.className = "w3-bar-item w3-button w3-dark-gray w3-text-light-gray";
                tabContent.className = "w3-show";
            } else {
                tab.className = "w3-bar-item w3-button";
                tabContent.className = "w3-hide";
            }
        }

        formRef.current = tabContentRefs[currentTab].current;
    }, [currentTab, formRef, tabContentRefs, tabRefs])


    return (<>
            <div className="w3-padding">
                <h2>{t("product.header")}</h2>
                <div className="w3-bar w3-round w3-light-gray w3-margin-bottom">
                    <button ref={tabRefs[0]} onClick={() => setCurrentTab(0)}>{t("product.description")}</button>
                    <button ref={tabRefs[1]} onClick={() => setCurrentTab(1)}>{t("product.list")}</button>
                    <button ref={tabRefs[2]} onClick={() => setCurrentTab(2)}>{t("product.url")}</button>
                </div>

                <form ref={tabContentRefs[0]}>
                <textarea name="product_input" className="w3-input w3-light-gray w3-round"
                          style={{resize: "vertical"}}
                          defaultValue="CPT voice bot is an innovative tool for live transcription"/>
                </form>

                <form ref={tabContentRefs[1]}>

                    <p><label>
                        <input name="product_input" type="radio" className="w3-radio w3-margin-right"
                               value="Nike Air Jordan 1 shoes" defaultChecked={true}/>
                        Nike Air Jordan 1 shoes
                    </label></p>
                    <p><label>
                        <input name="product_input" type="radio" className="w3-radio w3-margin-right"
                               value="Monopoly board game"/>
                        Monopoly board game
                    </label></p>
                    <p><label>
                        <input name="product_input" type="radio" className="w3-radio w3-margin-right"
                               value="Fitness Platinium gym membership"/>
                        Fitness Platinium gym membership
                    </label></p>
                </form>

                <form ref={tabContentRefs[2]}>
                    <textarea name="product_input" className="w3-input w3-light-gray w3-round"
                              style={{resize: "vertical"}}
                              defaultValue="https://www.xbox.com/pl-PL/xbox-game-pass?xr=footnav"/>
                </form>
            </div>
        </>
    );
}
