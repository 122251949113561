import React, {useEffect, useRef, useState} from "react";

export default function CompaniesOutput({companyDescriptions}) {
    const [currentTab, setCurrentTab] = useState(0);
    const tabContainerRef = useRef();
    const tabContentContainerRef = useRef();

    useEffect(() => {
        const tabs = tabContainerRef.current.children;
        const tabsContent = tabContentContainerRef.current.children;
        for (let i = 0; i < tabsContent.length; i++) {
            const tab = tabs[i];
            const tabContent = tabsContent[i];
            if (i === currentTab) {
                tab.className = "w3-bar-item w3-button w3-dark-gray w3-text-light-gray";
                tabContent.className = "w3-show";
            } else {
                tab.className = "w3-bar-item w3-button";
                tabContent.className = "w3-hide";
            }
        }
    }, [currentTab])

    return (<>
        <div className="w3-margin w3-padding w3-input w3-light-gray w3-round">
            <div ref={tabContainerRef} className="w3-bar w3-round w3-light-gray w3-margin-bottom">
                {Object.entries(companyDescriptions).map(
                    (value, index, array) => (
                        <button onClick={() => setCurrentTab(index)}>{index + 1}</button>
                    )
                )}
            </div>

            <div ref={tabContentContainerRef}>
                {Object.entries(companyDescriptions).map(
                    (value, index, array) => (
                        <div>
                            <h3>{value[0]}:</h3>
                            <textarea className="w3-input w3-light-gray w3-round" style={{resize: "vertical"}}
                                      defaultValue={value[1]}/>
                        </div>
                    )
                )}
            </div>
        </div>
    </>);
}
