export default function About() {
    return (
        <>
            {/* About Section */}
            <div className="w3-container w3-padding background-alternating-1" id="about">
                <h1 className="w3-padding"><b>Delegate AI</b></h1>
                <div className="w3-padding w3-large">
                    <p>
                        Wdrażamy technologie oraz tworzymy narzędzia AI dostosowane do specyfiki potrzeb naszych
                        klientów. Nasz zespół tworzy grupa profesjonalistów doświadczonych w branży IT i modelowaniu
                        językowym.
                    </p>
                    {/*<p>*/}
                    {/*    W naszej pracy cenimy sobie profesjonalizm, komunikację i indywidualne podejście do naszych*/}
                    {/*    partnerów. Nasza siedziba mieści się w Krakowie, a więc w mieście, które stanowi wiodące centrum*/}
                    {/*    usług IT w Polsce.*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*    Oferujemy narzędzia i usługi opierające się o zastosowanie technologii AI, a zwłaszcza*/}
                    {/*    modelowania językowego w celu optymalizacji pracy naszych klientów, z największą dbałością o ich*/}
                    {/*    indywidualne potrzeby i wartości, którymi się kierują.*/}
                    {/*</p>*/}
                </div>

                <div className="w3-row-padding w3-center w3-margin-top">
                    <div className="w3-third">
                        <i className="fa fa-code-fork w3-margin-bottom w3-jumbo w3-center"></i>
                        <p className="w3-xlarge">Wdrażanie technologii AI</p>
                        <div className="w3-large">
                            <p>
                                Technologie AI, takie jak Chat GPT, zoptymalizowane i dostosowane do konkretnych potrzeb
                                Państwa firmy.
                            </p>
                            {/*<p>*/}
                            {/*    Może to być na przykład bot służący do podstawowej komunikacji z Państwa klientami,*/}
                            {/*    czy technologia wyszukująca dla Państwa potrzebne informacje biznesowe.*/}
                            {/*</p>*/}
                        </div>
                    </div>

                    <div className="w3-third">
                        <i className="fa fa-search-plus w3-margin-bottom w3-jumbo"></i>
                        <p className="w3-xlarge">Wyszukiwanie nowej ery</p>
                        <div className="w3-large ">
                            <p>
                                Skorzystanie z naszej oferty wyszukiwania pozwoli Państwu zaoszczędzić czas i zwolni
                                 z części monotonnych, powtarzalnych zadań.
                            </p>
                            {/*<p>*/}
                            {/*    Zamiast przeglądania wyników wyszukiwarki, dostają Państwo gotowe i wyselekcjonowane już*/}
                            {/*    informacje odpowiadające Państwa zapytaniu.*/}
                            {/*</p>*/}
                        </div>
                    </div>

                    <div className="w3-third">
                        <i className="fa fa-user-md w3-margin-bottom w3-jumbo"></i>
                        <p className="w3-xlarge">Konsultacje</p>
                        <div className="w3-large">
                            <p>
                                Pomożemy Państwu zdefiniować Państwa potrzeby i przestrzenie działalności, które
                                można zoptymalizować za pomocą technologii AI.
                            </p>
                            {/*<p>*/}
                            {/*    Realizacja naszych usług zapewni Państwa firmie dalszy rozwój, optymalizację i*/}
                            {/*    konkurencyjność na rynku.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}