import {Outlet} from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Layout.css"

export default function Layout() {
    return (
        <div id="wrapper">
            <Navbar/>
            <div id="outlet bgimg-1">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}