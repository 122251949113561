import "./Header.css"

export default function Header() {
    return (
        <>
            {/* Header with full-height image */}
            {/*<header className="bgimg-1 w3-display-container w3-grayscale" id="home">*/}
            <header className="header-bg w3-display-container" id="home">
                <div className="w3-display-left w3-text-white header-text" style={{padding: "48px"}}>
                    <div className="w3-jumbo w3-hide-small">AI dla każdego</div>
                    <div className="w3-xxlarge w3-hide-large w3-hide-medium">AI dla każdego</div>
                    <div className="w3-large">Zaoszczędź czas dzięki nowoczesnej, przełomowej technologii.</div>
                    {/* <p><a href="#about"
                          className="w3-button w3-white w3-padding-large w3-large w3-margin-top w3-opacity w3-hover-opacity-off">
                        Learn more and start today
                    </a></p>*/}
                </div>

                {/*Social media links, should be added*/}
                {/*<div className="w3-display-bottomleft w3-text-grey w3-large" style={{padding: "24px 48px"}}>*/}
                {/*    <i className="fa fa-facebook-official w3-hover-opacity"></i>*/}
                {/*    <i className="fa fa-instagram w3-hover-opacity"></i>*/}
                {/*    <i className="fa fa-snapchat w3-hover-opacity"></i>*/}
                {/*    <i className="fa fa-pinterest-p w3-hover-opacity"></i>*/}
                {/*    <i className="fa fa-twitter w3-hover-opacity"></i>*/}
                {/*    <i className="fa fa-linkedin w3-hover-opacity"></i>*/}
                {/*</div>*/}
            </header>
        </>
    );
}