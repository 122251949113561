export default function Questions() {
    return (
        <>
            <div className="w3-container w3-padding w3-large background-alternating-2">
                <h1 className="w3-padding">
                    <i className="fa fa-comments-o" aria-hidden="true"/>
                    Jesteśmy otwarci na Państwa pomysły i potrzeby!
                </h1>

                <ul className="w3-ul w3-card-4 w3-margin-left w3-margin-right">
                    <li>
                        Państwa pracownicy tracą dużą ilość czasu na powtarzalne i rutynowe czynności?
                    </li>
                    <li>
                        Mają Państwo pomysł na optymalizację zadania lub zakresu zadań w firmie poprzez automatyzację,
                        jednak nie wiedzą Państwo, jak zabrać się za realizację pomysłu?
                    </li>
                    <li>
                        Są Państwo zainteresowani wdrażaniem technologii AI w Państwa działalności, jednak potrzebują
                        pomocy i konsultacji?
                    </li>
                </ul>

                <div className="w3-padding">
                    <p>
                        Jeśli odpowiedzieli Państwo “Tak” na jedno z powyższych pytań, oznacza to, że są Państwo
                        we właściwym miejscu.
                    </p>
                    {/*<p>*/}
                    {/*    Współpraca z nami zapewni Państwu nie tylko teoretyczne odpowiedzi na poruszone kwestie, ale*/}
                    {/*    przede wszystkim pomoże Państwu rozwiązać je praktycznie za pomocą naszych skutecznych*/}
                    {/*    dedykowanych technologii, narzędzi i rozwiązań.*/}
                    {/*</p>*/}
                </div>
            </div>
        </>
    );
}