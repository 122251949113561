import {useTranslation} from "react-i18next";


const languages = {
    en: {nativeName: "English"},
    pl: {nativeName: "Polski"}
}


export default function LanguageSelector() {
    const {t, i18n} = useTranslation('common');

    return (
        <>
            <div className="w3-dropdown-hover">
                <button className="w3-button">{ t('chooseLanguage') }</button>
                <div className="w3-dropdown-content w3-bar-block">
                    {Object.keys(languages).map((language) => (
                        <button
                            key={language}
                            className="w3-bar-item w3-button"
                            style={{fontWeight: i18n.resolvedLanguage === language ? 'bold' : 'normal'}}
                            onClick={() => i18n.changeLanguage(language)}
                        >
                            {languages[language].nativeName}
                        </button>
                    ))}
                </div>
            </div>
        </>
    )
}
