import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Spinner from "../spinner/Spinner";


export default function TableQuestion() {
    const [response, setResponse] = useState("");
    const [output, setOutput] = useState(<div/>);
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const {t, i18n} = useTranslation("common");

    useEffect(() => {
        if (loading) {
            setOutput(<Spinner/>);
        } else if (response !== "") {
            setOutput(
                <textarea className="w3-input w3-light-gray w3-round w3-padding" style={{resize: "vertical"}}
                          defaultValue={response}/>
            );
        }
    }, [response, loading]);

    async function submitSampleFile(event) {
        const formData = new FormData();
        const blob = await fetch("/sample_files/sample_excel.xls").then(r => r.blob());
        formData.append("data", blob);
        formData.append("question", "How old is Shavon Benito?")
        submitFormData(formData);
    }

    function handleSubmit(event) {
        event.preventDefault();
        submitFormData(new FormData(formRef.current));
    }

    function submitFormData(formData) {
        formData.append("language", i18n.resolvedLanguage);

        setLoading(true);
        fetch("/table-question", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()
        ).then(data => {
            setLoading(false);
            setResponse(data.output);
        });
    }

    return (
        <>
            <div style={{padding: "60px"}}>
                <form onSubmit={handleSubmit} className="w3-margin-bottom" ref={formRef}>
                    <input
                        id="data" name="data" type="file"
                        accept="application/vnd.ms-excel" className="w3-input"
                    />
                    <textarea id="question" name="question"
                              className="w3-input w3-light-gray w3-round w3-padding w3-margin-bottom w3-margin-top"/>
                    <input type="submit" className="w3-button w3-green w3-round-medium" value={t("submit")}/>
                </form>
                <button className="w3-button w3-round w3-blue" onClick={submitSampleFile}>{t("sample.excel")}</button>
                <p>How old is Shavon Benito?</p>
                {output}
            </div>
        </>
    )
}
