import Header from "./Header";
import About from "./About";
import Questions from "./Questions";
import Skills from "./Skills";
import Contact from "./Contact";
import "./Homepage.css"

export default function Homepage() {
    return (
        <div className="bgimg-1">
            <Header/>
            <About/>
            {/*<div className="divider w3-container"/>*/}
            {/*<Skills/>*/}
            {/*<div className="divider w3-container"/>*/}
            <Questions/>
            {/*<div className="divider w3-container"/>*/}
            <Contact/>
        </div>
    );
}