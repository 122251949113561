// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
    background-color: #fff;
    width: 10vw;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.spinner-container p {
    text-align: center;
    margin: 5px 0 0 0;
}

#upper {
    animation: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform-origin: center;
}

@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,mCAAmC;IACnC,wBAAwB;AAC5B;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".spinner-container {\n    background-color: #fff;\n    width: 10vw;\n    padding: 30px;\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    flex-direction: column;\n}\n\n.spinner-container p {\n    text-align: center;\n    margin: 5px 0 0 0;\n}\n\n#upper {\n    animation: rotate;\n    animation-duration: 5s;\n    animation-iteration-count: infinite;\n    transform-origin: center;\n}\n\n@keyframes rotate {\n    0% {\n        transform: rotateZ(0deg);\n    }\n    100% {\n        transform: rotateZ(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
