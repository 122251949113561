// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/eryk.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgimg-1 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
  background-attachment: fixed;
  filter: grayscale(40%);
  opacity: 0.9;

  padding-bottom: 15%;
  /*margin-bottom: -15%;*/
}

.header-bg {
  min-height: 100vh;
  /*background-color: transparent;*/
}

.header-text {
  text-shadow: 0 0 5px black;
  background-color: rgba(0, 0, 0, 0.8);
  color: lightgray;

  width: 60%;
  margin-left: 5%;
  box-shadow: 0 0 5px lightgray;
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/Header.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,yDAAwD;EACxD,iBAAiB;EACjB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;;EAEZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,iCAAiC;AACnC;;AAEA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,gBAAgB;;EAEhB,UAAU;EACV,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".bgimg-1 {\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-image: url(\"../../../public/images/eryk.png\");\n  min-height: 100vh;\n  background-attachment: fixed;\n  filter: grayscale(40%);\n  opacity: 0.9;\n\n  padding-bottom: 15%;\n  /*margin-bottom: -15%;*/\n}\n\n.header-bg {\n  min-height: 100vh;\n  /*background-color: transparent;*/\n}\n\n.header-text {\n  text-shadow: 0 0 5px black;\n  background-color: rgba(0, 0, 0, 0.8);\n  color: lightgray;\n\n  width: 60%;\n  margin-left: 5%;\n  box-shadow: 0 0 5px lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
