import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_en from "./translations/en/common.json"
import common_pl from "./translations/pl/common.json"


i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: {escapeValue: false},
        fallbackLng: 'en',
        resources: {
            en: {
                common: common_en,
            },
            pl: {
                common: common_pl,
            }
    }
});

export default i18next;