import "./spinner.css";
import {useTranslation} from "react-i18next";

export default function Spinner() {
    const {t, i18n} = useTranslation("common");

    return (
        <div className="spinner-container">
            <svg viewBox="0 0 276 276" fill="none">
                <g id="spinner">
                    <circle id="bottom" cx="138" cy="138" r="114" stroke="#DBDBDB" strokeWidth="18"/>
                    <circle id="upper" cx="138" cy="138" r="123" stroke="#72BBFF" strokeWidth="30" strokeLinecap="round"
                            strokeLinejoin="round" strokeDasharray="373 100"
                    />
                </g>
            </svg>
            <p>{t("loading")}</p>
        </div>

    );
}