import {useRef} from "react";
import LanguageSelector from "./LanguageSelector";

export default function Navbar() {
    const sidebarRef = useRef(null);

    function toggle_sidebar() {
        if (sidebarRef.current.style.display === 'block') {
            sidebarRef.current.style.display = 'none';
        } else {
            sidebarRef.current.style.display = 'block';
        }
    }

    // Close the sidebar with the close button
    function close_sidebar() {
        sidebarRef.current.style.display = "none";
    }

    return (
        <>
            {/* Navbar(sit on top) */}
            <div className="w3-top">
                <div className="w3-bar w3-black w3-text-light-gray w3-card" id="myNavbar">
                    <a href="/#home" className="w3-bar-item w3-button w3-wide">DELEGATE AI</a>
                    {/*Right-sided navbar links */}
                    <div className="w3-right w3-hide-small w3-hide-medium">
                        <a href="/#about" className="w3-bar-item w3-button">O NAS</a>
                        {/*<a href="/#skills" className="w3-bar-item w3-button"> NASZE SPECJALNOŚCI</a>*/}
                        <a href="/#contact" className="w3-bar-item w3-button"><i
                            className="fa fa-envelope"/> KONTAKT
                        </a>
                        {/*<LanguageSelector/>*/}
                    </div>

                    {/* Hide right-floated links on small screens and replace them with a menu icon */}
                    <button className="w3-bar-item w3-button w3-right w3-hide-large"
                            onClick={toggle_sidebar}>
                        <i className="fa fa-bars"></i>
                    </button>
                </div>
            </div>

            {/* Sidebar on small screens when clicking the menu icon */}
            <nav className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large"
                 style={{display: "none"}} id="mySidebar" ref={sidebarRef}>
                <button onClick={close_sidebar} className="w3-bar-item w3-button w3-large w3-padding-16">
                    Zamknij ×
                </button>
                <a href="/#about" onClick={close_sidebar} className="w3-bar-item w3-button">O NAS</a>
                {/*<a href="/#skills" onClick={close_sidebar} className="w3-bar-item w3-button">NASZE SPECJALNOŚCI</a>*/}
                <a href="/#contact" onClick={close_sidebar} className="w3-bar-item w3-button">
                    <i className="fa fa-envelope"/> KONTAKT
                </a>
                {/*<LanguageSelector/>*/}
            </nav>
        </>
    );
}