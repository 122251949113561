import React, {useEffect, useRef, useState} from "react";
import Companies from "./Companies";
import {useTranslation} from "react-i18next";
import CompaniesOutput from "./CompaniesOutput";
import Product from "./Product";
import Spinner from "../spinner/Spinner";


export default function Marketing() {
    const {t, i18n} = useTranslation("common");
    const companiesFormRef = useRef();
    const productFormRef = useRef();
    const [productResponse, setProductResponse] = useState("");
    const [companiesResponse, setCompaniesResponse] = useState("");
    const [productOutput, setProductOutput] = useState(<div/>);
    const [companiesOutput, setCompaniesOutput] = useState(<div/>);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            setProductOutput(<Spinner/>);
            setCompaniesOutput(<Spinner/>);
        } else if (productResponse !== "" && companiesResponse !== "") {
            setProductOutput(
                <div className="w3-padding">
                    <h3>{t("product.output")}</h3>
                    <textarea className="w3-input w3-light-gray w3-round w3-padding" style={{resize: "vertical"}}
                              defaultValue={productResponse}/>
                </div>
            );

            setCompaniesOutput(
                <CompaniesOutput companyDescriptions={companiesResponse}/>
            );
        }
    }, [loading, companiesResponse, productResponse]);

    function combineForms(...formsData) {
        const formData = new FormData();
        for (let form of formsData) {
            for (let entry of form.entries()) {
                formData.set(entry[0], entry[1]);
            }
        }
        return formData;
    }

    function submitFormData() {
        setLoading(true);

        const productData = new FormData(productFormRef.current);
        const companiesData = new FormData(companiesFormRef.current);
        companiesData.set("match_type", companiesFormRef.current.id);
        const formData = combineForms(productData, companiesData);

        fetch("/marketing", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()
        ).then(data => {
            setLoading(false);
            setProductResponse(data["product_output"])
            setCompaniesResponse(data["companies_output"]);
        });
    }

    return (
        <>
            <div style={{padding: "60px"}}>
                <div className="w3-container w3-round-medium w3-border w3-margin-bottom">
                    <Product formRef={productFormRef}/>
                    {productOutput}
                </div>
                <div className="w3-container w3-round-medium w3-border w3-margin-bottom">
                    <Companies formRef={companiesFormRef}/>
                    {companiesOutput}
                </div>
                <button className="w3-button w3-green w3-round-medium" onClick={submitFormData}>{t("submit")}</button>
            </div>
        </>
    );
}
