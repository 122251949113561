import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Spinner from "../spinner/Spinner";


export default function Transcribe() {
    const [response, setResponse] = useState("");
    const [output, setOutput] = useState(<div/>);
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const {t, i18n} = useTranslation("common");

    useEffect(() => {
        if (loading) {
            setOutput(<Spinner/>);
        } else if (response !== "") {
            setOutput(
                <textarea className="w3-input w3-light-gray w3-round w3-padding" style={{resize: "vertical"}}
                          defaultValue={response}/>
            );
        }
    }, [response, loading]);

    async function submitSampleFile(event) {
        const formData = new FormData();
        const blob = await fetch("/sample_files/sample_audio.ogg").then(r => r.blob());
        formData.append("media-file", blob);
        submitFormData(formData);
    }

    function handleSubmit(event) {
        event.preventDefault();
        submitFormData(new FormData(formRef.current));
    }

    function submitFormData(formData) {
        formData.append("language", i18n.resolvedLanguage);

        setLoading(true);
        fetch("/transcribe", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()
        ).then(data => {
            setLoading(false);
            setResponse("Transcription model output:\n" + data.output);
        });
    }

    return (
        <>
            <div style={{padding: "60px"}}>
                <form onSubmit={handleSubmit} style={{marginBottom: "30px"}} ref={formRef}>
                    <input
                        className="w3-input"
                        id="media-file" name="media-file" type="file"
                        accept="audio/*, video/*"
                    />
                    <input className="w3-button w3-green w3-round-medium" type="submit" value={t("submit")}/>
                </form>

                <button
                    className="w3-button w3-blue w3-round-medium"
                    onClick={submitSampleFile}
                >{t("sample.audio")}</button>

                {output}
            </div>
        </>
    )
}
