import {useEffect, useRef, useState} from "react";
import validator from "validator";
import MD5 from "crypto-js/md5";

export default function Contact() {
    const [email, setEmail] = useState("");
    const [emailClasses, setEmailClasses] = useState("");
    const [message, setMessage] = useState("");
    const [messageClasses, setMessageClasses] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const [emailSent, setEmailSent] = useState(false);
    const [emailStatus, setEmailStatus] = useState(<div/>);
    const [buttonDisabledClass, setButtonDisabledClass] = useState("");
    const timestamp = useRef(0);
    const hash = useRef("");

    useEffect(() => {
        timestamp.current = new Date().getTime();
    }, []);

    useEffect(() => {
        if (validateForm()) {
            setDisableButton(false);
            setButtonDisabledClass("");
        } else {
            setDisableButton(true);
            setButtonDisabledClass("w3-disabled");
        }
    }, [email, message]);

    function validateForm() {
        let isValid = true

        if (email === "") {
            isValid = false;
            setEmailClasses("")
        } else {
            if (!validator.isEmail(email)) {
                isValid = false;
                setEmailClasses("w3-border-red w3-pale-red");
            } else {
                setEmailClasses("w3-border-green");
            }
        }

        if (message.length === 0) {
            isValid = false;
            setMessageClasses("");
        } else {
            if (message.replace(/\s/g, '') === "") {
                isValid = false;
                setMessageClasses("w3-border-red w3-pale-red");
            } else {
                setMessageClasses("w3-border-green");
            }
        }

        return isValid;
    }

    function sendMessage(event) {
        event.preventDefault();

        if (validateForm()) {
            hash.current = MD5(timestamp.current.toString() + message).toString();

            setEmailSent(true);
            setEmailStatus(<div className="w3-large w3-padding">
                <i className="fa fa-pulse fa-spinner"/> Wysyłanie...
            </div>);

            fetch("/email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    message: message,
                    timestamp: timestamp.current,
                    hash: hash.current
                })
            }).then(res => {
                if (res.ok) {
                    setEmailStatus(<div className="w3-large w3-padding">
                        <i className="fa fa-check"/> Wiadomość wysłana. Dziękujemy za kontakt.
                    </div>)
                } else {
                    setEmailStatus(<div className="w3-large w3-padding">
                        <i className="fa fa-times"/> Coś poszło nie tak. Spróbuj ponownie.
                    </div>)
                }
            })
        }
    }

    return (
        <>
            {/* Contact Section */}
            <div className="background-alternating-1" id="contact">
                <div className="w3-padding">
                    <h3 className="w3-padding">
                        <i className="fa fa-phone" aria-hidden="true"></i> Zapraszamy do kontaktu!
                    </h3>
                    <p className="w3-large w3-padding">Zostaw nam swoje dane i opisz krótko profil Państwa działalności.
                        <br></br>Nasz konsultant skontaktuje się z Tobą w celu zaproponowania i omówienia optymalnych,
                        nowoczesnych rozwiązań dostosowanych do Twojej firmy.
                        <br></br>Wejdź z nami w nowy poziom technologii AI!
                        <br></br>
                        {/*<br></br> <b>Email kontaktowy: delegateai</b>*/}
                    </p>
                </div>
                <div className="w3-container w3-padding" style={{marginTop: "24px"}}>
                    <form className="w3-container w3-padding">
                        <p><input className={`w3-input w3-border w3-padding ${emailClasses}`}
                                  type="text" placeholder="Email kontaktowy" required name="Email"
                                  onChange={e => setEmail(e.target.value)}
                        /></p>

                        <p><textarea className={`w3-input w3-border w3-padding textarea-vertical ${messageClasses}`}
                                     maxLength="250" placeholder="Twoja wiadomość" required name="Message"
                                     onChange={e => setMessage(e.target.value)}
                        /></p>

                        {emailSent ? "" :
                            <button className={`w3-button w3-border-light-gray ${buttonDisabledClass}`} type="submit"
                                    disabled={disableButton} onClick={sendMessage}>
                                <i className="fa fa-paper-plane"></i> WYŚLIJ WIADOMOŚĆ
                            </button>
                        }

                        {emailStatus}
                    </form>
                </div>
            </div>
        </>
    );
}